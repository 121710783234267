.tiles-grid {
    padding: 10px;
}

.tiles-grid>a:hover {
    text-decoration: none !important;
    color: #fff;
}

.td-icon,
.td-icon-md {
    color: white;
}

.theme-gradient-primary-secondary {
    color: #fff;
    background-color: #c31b96;
    background: linear-gradient(to right, #c31b96 0, #841f8d 100%)
}

.accordion>.frame>.heading {
    display: flex !important;
    justify-content: space-between;
    background: black;
    font-size: 1.2em;
    border-radius: 5px;
    align-items: center;
}

.tile-small {
    -webkit-transition: none .1s linear, opacity .3s ease-in-out;
    -moz-transition: none .1s linear, opacity .3s ease-in-out;
    -o-transition: none .1s linear, opacity .3s ease-in-out;
    transition: none .1s linear, opacity .3s ease-in-out;
}

.tile-medium,
.tile-wide {
    -webkit-transition: all .2s ease-in-out, opacity .3s ease-in-out;
    -moz-transition: all .2s ease-in-out, opacity .3s ease-in-out;
    -o-transition: all .2s ease-in-out, opacity .3s ease-in-out;
    transition: all .2s ease-in-out, opacity .3s ease-in-out;
}

.tile-medium.clickable:hover {
    background-size: 130% 150% !important;
}

.tile-wide.clickable:hover {
    background-size: 120% 120% !important;
}
.tile-small.clickable, .tile-medium.clickable, .tile-wide.clickable {
    backface-visibility: hidden;
    -webkit-backface-visibility:hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
    transform: translateZ(0);
    -webkit-font-smoothing: antialiased !important;
}

.tile-small:hover,
.tile-medium:hover,
.tile-wide:hover {
    opacity: 1 !important;
    -webkit-animation: Gradient 2s ease infinite !important;
    -moz-animation: Gradient 2s ease infinite !important;
    animation: Gradient 2s ease infinite !important;
}

.tile-small .icon {
    font-size: 1.2em !important;
}

#s2p-product-modal-input-head {
    border-top: 1px solid rgba(0, 0, 0, .125);
    padding-top: 8px;
    padding-left: 12px;
    padding-right: 12px;
}

#sales-force-panel {
    border-top: 1px solid rgba(0, 0, 0, .125);
    padding-top: 8px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 40px;
}




.generate-serviceId-button{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.generate-serviceId-input{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.rangeRow{
     overflow: visible;
      position: relative;
      z-index: 999; 
}

.product-header-box:hover {
 cursor: pointer;
}


.pm-waringMessage{
    padding-left: 40px;
    font-size: 80%;
    color: #dc3545;
}

.adbor-fnn-head>tr>th{
    padding-top: 0px;
    padding-bottom: 0px;
}
.ranges-fnn-head>tr>th{
    padding-top: 0px;
    padding-bottom: 0px;
}

.adborFnnTableInputDiv{
    margin-bottom: 0px;
}


