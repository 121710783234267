.tiles-grid {
    padding: 20px;
    
}

.tiles-grid > a:hover {
    text-decoration: none !important;
    color: #fff;
}

.td-icon, .td-icon-md {
    color: white !important;
}

 .theme-gradient-primary-secondary{
    color:#fff;
    background-color:#c31b96;
    background:linear-gradient(to right, #c31b96 0, #841f8d 100%)
}

.accordion>.frame>.heading {
    display: flex !important;
    justify-content: space-between;
    background: black;
    font-size: 1.2em;
    border-radius: 5px;
    align-items: center;
}

.tile-small {
    -webkit-transition: none .1s linear, opacity .3s ease-in-out;
    -moz-transition: none .1s linear, opacity .3s ease-in-out;
    -o-transition: none .1s linear, opacity .3s ease-in-out;
    transition: none .1s linear, opacity .3s ease-in-out;
}

.tile-medium {
    -webkit-transition: none .3s ease-in-out, opacity .3s ease-in-out;
    -moz-transition: none .3s ease-in-out, opacity .3s ease-in-out;
    -o-transition: none .3s ease-in-out, opacity .3s ease-in-out;
    transition: none .3s ease-in-out, opacity .3s ease-in-out;
}

.tile-small:hover, .tile-medium:hover {
    opacity: 1 !important
}

.tile-small .icon {
    font-size: 1.2em !important;
}

.loadingIconWrapper {
    text-align:center;
    min-height: 30px;
}



/*loadingIcon1 start*/
/* .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 10vh;
  background-color: none;
} */

.loader {
  max-width: 15rem;
  width: 25%;
  height: auto;
  stroke-linecap: round;
}

.loader > circle {
  fill: none;
  stroke-width: 3.5;
  -webkit-animation-name: preloader;
          animation-name: preloader;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-transform-origin: 170px 170px;
          transform-origin: 170px 170px;
  will-change: transform;
}
.loader > circle:nth-of-type(1) {
  stroke-dasharray: 550;
}
.loader > circle:nth-of-type(2) {
  stroke-dasharray: 500;
}
.loader > circle:nth-of-type(3) {
  stroke-dasharray: 450;
}
.loader > circle:nth-of-type(4) {
  stroke-dasharray: 300;
}
.loader > circle:nth-of-type(1) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
.loader > circle:nth-of-type(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.loader > circle:nth-of-type(3) {
  -webkit-animation-delay: -0.45s;
  -moz-animation-delay:  -0.45s;
          animation-delay: -0.45s;
}
.loader > circle:nth-of-type(4) {
  -webkit-animation-delay: -0.6s;
  -moz-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

@-webkit-keyframes preloader {
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes preloader {
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
  
/*loadingIcon1 end*/


/*loadingIcon2 start*/
/* .loader{
  width: 50px;
  height: 50px;
  margin: 40px auto;
  transform: rotate(-45deg);
  font-size: 0;
  line-height: 0;
  animation: rotate-loader 5s infinite;
  padding: 25px;
  border:1px solid mediumseagreen;
  
}
.loader .loader-inner{
  position: relative;
  display: inline-block;
  width: 50%;
  height: 50%;
}
.loader .loading{
  position: absolute;
  background: forestgreen;
}
.loader .one{
  width: 100%;
  bottom: 0;
  height: 0;
  animation: loading-one 1s infinite;
}
.loader .two{
  width: 0;
  height: 100%;
  left: 0;
  animation: loading-two 1s infinite;
  animation-delay: 0.25s;
}
.loader .three{
  width: 0;
  height: 100%;
  right: 0;
  animation: loading-two 1s infinite;
  animation-delay: 0.75s;
}
.loader .four{
  width: 100%;
  top: 0;
  height: 0;
  animation: loading-one 1s infinite;
  animation-delay: 0.5s;
}
@keyframes loading-one {
  0% {
      height: 0;
      opacity: 1;
  }
  12.5% {
      height: 100%;
      opacity: 1;
  }
  50% {
      opacity: 1;
  }
  100% {
      height: 100%;
      opacity: 0;
  }
}
@keyframes loading-two {
  0% {
      width: 0;
      opacity: 1;
  }
  12.5% {
      width: 100%;
      opacity: 1;
  }
  50% {
      opacity: 1;
  }
  100% {
      width: 100%;
      opacity: 0;
  }
}
@keyframes rotate-loader {
  0% {
      transform: rotate(-45deg);
  }
  20% {
      transform: rotate(-45deg);
  }
  25% {
      transform: rotate(-135deg);
  }
  45% {
      transform: rotate(-135deg);
  }
  50% {
      transform: rotate(-225deg);
  }
  70% {
      transform: rotate(-225deg);
  }
  75% {
      transform: rotate(-315deg);
  }
  95% {
      transform: rotate(-315deg);
  }
  100% {
      transform: rotate(-405deg);
  }
} */
/*loadingIcon2 end*/