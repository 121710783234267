.simas-form {
    width: 100%;
    padding: 15px;
    margin: 0 auto;
}

.newAttribute {
    display: inline-flex;
    width: 100%;
}

.plus-btn {
    margin-top: auto;
    margin-bottom: 0;
    margin-left: 4px;
    color: green !important;
    background: none !important;
    box-shadow: 0px 0px 3px 0px green !important;
}

.simas-form-btn {
    width: 120px;
}

.simas-cancel-btn {
    margin-left: 40px;
}