.invalid {
  color: red;
  font-size: x-small;
}
.valid {
  display: none;
}

.status {
  padding-bottom: 15px;
  padding-top: 15px;
  background-color: #dee6e48c;
  border-radius: 5px;
  padding-left: 5px;
}
.cleanup-text-margin {
  margin-top: -1.7rem;
}