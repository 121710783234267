.headerToggleButtonGroup
{

}
.headerToggleButtonGroup .Mui-selected, .headerToggleButtonGroup .Mui-selected:hover {
   color: #4d9be7;
    background-color: #ffffff;
    border: 1px solid white;
    border-radius: 5px;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

p.MuiTypography-root.makeStyles-headerLabel-8.MuiTypography-body1 {
    color: black !important;
}
.scopeToggleButtonGroup .Mui-selected, .scopeToggleButtonGroup .Mui-selected:hover {
    color: #4d9be7;
     background-color: #ffffff;
     border: 1px solid white;
     border-radius: 5px;
     border-top-right-radius: 5px !important;
     border-bottom-right-radius: 5px !important;
 }
button.MuiButtonBase-root.MuiToggleButton-root.Mui-selected.MuiToggleButton-sizeMedium.MuiToggleButton-standard.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal {
    background-color: white;
}

span.MuiCardHeader-title.MuiTypography-body2.MuiTypography-root.css-et1ao3-MuiTypography-root {
    font-weight: bold;
    font-size: large;
}
.toggleButtonPanel
{
    background-color  :#28a745;
    width: 300px !important;
    padding-bottom: 4px;
    padding-left: 4px;
    margin-top : 5px;
    border-radius: 5px !important

}
.headerToggleButtonGroup button:focus , .scopeToggleButtonGroup button:focus
{
    outline: none !important;

}
.headerToggleButtonGroup .MuiToggleButtonGroup-grouped:not(:first-of-type),
.headerToggleButtonGroup .MuiToggleButtonGroup-grouped:not(:last-of-type)
{
    border : 0px !important;
    width: 138px !important
}
.scopeToggleButtonGroup .MuiToggleButtonGroup-grouped:not(:first-of-type),
.scopeToggleButtonGroup .MuiToggleButtonGroup-grouped:not(:last-of-type)
{
    border : 0px !important;
    width: 110px !important
}
.headerToggleButtonGroup .MuiToggleButtonGroup-grouped:not(:first-of-type)
{
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    width: 138px !important
}
.scopeToggleButtonGroup .MuiToggleButtonGroup-grouped:not(:first-of-type)
{
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    width: 110px !important
}

.b2bContainer{
 border: 1px solid #d5dade;
padding: 0px 5px 36px 26px;
border-radius: 20px;
box-shadow: 0 0 10px #e2e5e7;
}
.b2bSubmitButton {
    width: 200px !important;
    height: 50px !important;
    background-color: #244389 !important;
    color: white !important;
}

.b2bSubmitButton.MuiButton-contained:hover {
    background-color: #48629b !important;
    color: white;
}
.b2bContainer #unms-range-header {
    font-size: 18px;
    background-color: transparent;
    padding-top: 30px;
    color: #254489 !important;
}

.paginationBtn .branding-bar{
    margin-bottom: 10px;
    margin-left: 35px;
}