#root,
body,
html {
    height: 100% !important;
}

body {
    margin: 0;
    padding: 0;
    font-family: TelstraAkkuratWeb-Regular, sans-serif !important;
    /* background-color: #FFF !important; */
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.root {
    position: inherit !important;
}



.icon-clip {
    background-clip: text !important;
    -webkit-background-clip: text !important;
    color: transparent !important;
}

.dataview-header .fa {
    padding-bottom: 0;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    opacity: 0.7;
}

.dataview-header span {
    display: flex;
    align-items: center;
}

.dataview-header .mainIcon {
    font-size: 4rem;
}
.dataview-header .fa:hover {
    opacity: 1;
}

.card-columns .card {
    margin-top: 12px !important;
    border: none !important;
}
@media (min-width: 576px) {
    .card-columns {
        column-count: 1 !important;
    }
}
@media (min-width: 992px) {
    .card-columns {
        column-count: 2 !important;
    }
}
@media (min-width: 1200px) {
    .card-columns {
        column-count: 3 !important;
    }
}

tr .table {
    background-color: transparent !important;
}

td .table {
    background-color: transparent !important;
}

tr .table.left {
    margin-left: 10px !important;
}

tr.row-hover:hover {
    background-color: rgba(0,0,0,.075);
    cursor: pointer;
}

.collapse {
    max-height: none !important;
}

.dropdown-toggle::before {
    display: none !important;
}