.btn-team{
    width: 7rem;
}

#team-qty{
height:45px

}
#team-usr-story{
    height:45px    
}

.my-l{
    margin:2rem 0;
}
.px-s{
padding: 0 0.5rem;
}
.pxr-s{
padding-right: 0.5rem;
}

.team-icon{
    color: #068e27 !important;
}

#team-detail-row:hover{
    background-color: #8fd19e24;
    color: black;
}

#team-details-table-body tr:first-child:hover{
    background-color: #8fd19e24;
    color: black;
}

#team-table-area{
    min-height: 30rem;
}

#team-view-page{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.team-allocate-qty-invalid{
    border-color: #dc3545 !important;
    border-width: 2px !important;
    box-shadow: 0 0 0 0.2rem rgba(255, 8, 0, 0.25) !important;
}
.team-usr-story-invalid{
    border-color: #dc3545 !important;
    border-width: 2px !important;
    box-shadow: 0 0 0 0.2rem rgba(255, 8, 0, 0.25) !important;
}

.team-circle{
    height: 45px;
    width: 55px;
    border-radius: 50%;
    font-weight: 600;
    text-align: center;
    vertical-align: middle ;
    font-size: larger;
    display: inline-block;
    line-height: 45px;
}
#team-qty-circle{
    border: 2.3px solid #4c479f;
    color: #4c479f;
}

#team-service-circle{
    color:#bb2914;
    border: 2.3px solid #bb2914;
}

.myteam-switch-selector { 
    display: flex;
    align-items: center;
}
/*css to match tdm */
/* .myteam-switch-selector .react-switch-selector-option {
    margin-top: 5px;
} */

.allocate-btn{
    opacity: 0.7; 
    cursor: pointer;
    margin-right: 25px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
    
}
.allocate-btn:hover{
    cursor: pointer;
    box-shadow: 0px 2px 1px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    opacity: 1;
}
.allocate-btn .icon{
    color:#068e27;
    font-size: 3em;
}
.color-red {
    color:#bb2914 !important;
    font-weight: 600;
}
.exchangeServicesPanel
{
    color: #676561;
    font-weight: 600;
    margin-top: 15px;
}
