.TConnectOrderModal {
}

.TConOrderModal--header {
    display: flex;
    justify-content: center;
}

.TConModal--data-grid {
    display: grid;
    justify-content: space-around;
    align-content: space-around;
    grid-template-rows: repeat(6, auto);
    grid-template-columns: repeat(2, auto);
}

.TConModal--data-grid>div {
    padding: 5px 0;
}

.TConModal--data-label {
    font-weight: bold;
}

.TConModal--switch {
    top: 8px !important;
    left: 5px !important;
}