.slider-control-centerleft {
    left: -40px !important;
    margin-left: -5px !important;
    top: 35% !important
}

.slider-control-centerleft button {
    margin-left: 5px;
    width: 40px;
}

.slider-control-centerright button {
    width: 38px;
}

.slider-control-centerright {
    right: -40px !important;
    margin-right: -55px;
    margin-left: 20px;
    top: 35% !important
}

.slider {
    margin-top: 2px;
    height: 125px !important;
    width: 90% !important;
    float: left;
    margin-left: 40px;
    margin-top: 14px;
}

.feedback_date {
    font-size: 12px;
    margin-left: 20px;
    margin-top: 0px;
}

.comment-text p {
    color: black;
    max-width: 220px;
    min-width: 120px;
    max-height: 45px;
    margin-left: -8px;
    margin-top: -2px;
    word-break: break-all;
}

.slider-control-bottomcenter {
    display: none;
}

.slider-slide ul {
    width: 97%;
    height: 92px;
    margin-left: 1px !important;
}

.comment-text span {
    width: 220px;
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #f5f5f5;
    font-weight: bold;
    margin-left: -10px;
}

.testimonial_bg {
    background: #1d1d1db0;
    height: 119px;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 1333px;
}

.testimonial_bg button {
    z-index: 1;
}

.feedback_bg {
    background: #ffffff6b;
    top: 30px;
    width: 500px !important;
    margin-left: 400px;
    height: 65px;
    border-radius: 12px;
}

.feedback_bg p {
    margin-left: 45px;
    font-size: 17px;
    padding-top: 22px;
    color: #f5f5f5;
}

.feedback_bg button {
    color: #ffffff;
    background: #0c8adf;
}

.feedbackTooltip{
    font-size: 15px !important;
}

/* .modal-body button {
    margin-left: 400px !important;
} */