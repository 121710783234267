.tiles-grid > .fa {
    font-size: 110%;
}

.tile-medium > span.branding-bar {
    font-size: 1rem !important;
}

span.branding-bar.user {
    font-size: 1.2rem !important;
}

span.badge-bottom {
    font-size: 16px !important;
}

.slide-back > p.text-center {
    font-size: 1.2rem;
}

.testimonialBox {
    opacity: 1;
    background-color: #3c3c3dd1;
    width: 101%;
    margin-left: 0px;
    margin-top: 52px;
    padding-bottom: 21px !important;
    border-radius: 20px !important;
}

.row .black{
    clear: both;
}