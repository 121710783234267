.diagram-grid img {
    display: block;
    max-height: 170px;
    width: 100%;
    height: auto;
}

.diagram-grid .col-sm-3 {
    transition: all 260ms linear;
}

.diagram-grid .card {
    border-radius: 5px;
    background-color: rgb(254, 254, 254);
    color: black;
    animation: FADE-IN-FROM-LEFT 700ms linear 0s forwards;
    height: 310px;
    opacity: 0;
}

.diagram-grid>.col-sm-3:nth-child(4n+2)>.card {
    animation-delay: 550ms;
}

.diagram-grid>.col-sm-3:nth-child(4n+3)>.card {
    animation-delay: 1100ms;
}

.diagram-grid>.col-sm-3:nth-child(4n+4)>.card {
    animation-delay: 1650ms;
}

.diagram-grid>.col-sm-3:hover {
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: all 260ms linear;
    transform: translateY(8px);
}

@keyframes FADE-IN-FROM-LEFT {
    0% {
        opacity: 0;
        transform: translateX(-40px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
        transition: all 260ms linear;
    }
}