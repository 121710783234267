.panel-container {
    opacity: 0.9;
    border: none !important;

    /* background: #a6c5c7 linear-gradient(45deg, #f4f7f8, transparent) !important; */
    /* background-color: rgba(255, 255, 255, 0.288); */
    display: flex;
    color: #000808; 
    box-shadow: inset 0 0 5px #089beb;
  }
  
  .right-bar {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .rightbar-title {
    display: flex;
    padding: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .m-0 {
    margin: 0;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
    font-size: 25px;
  }
  
  .mt-2 {
    margin-top: 0.5rem !important;
  }
  .p-2 {
    padding: 0.5rem !important;
  }

  .item-align-right {
    margin-right: 15px !important;
  }
  
  .border-top-sp {
     border-top: 1px solid #c6d1da !important; 
  }
  .element-relative-position {
    position: relative;
    bottom: 20px;
    margin-left: 5px;
  }

  .sliding-panel-container.active {
    background-color: rgba(7, 7, 7, 0.82);
    z-index:9;
}
