.training-icon
{
    color : #3A0CA3
}
#trainingDiv h1 {
    color: #740074;
    text-shadow: 0px 0px 1px #535153;
}
.trainingImage {
    object-fit: contain !important;
    padding-top: 20px;
    padding-bottom: 30px;
}
.trainingCards .MuiCardMedia-img {
    object-fit: contain !important;
    width: 17% !important;
    display: inline-block;
    height: 100%;
    padding: 0px;
}
.userTrainingCards .MuiCardMedia-img {
    object-fit: contain !important;
    display: inline-block;
    height: 100%;
    padding: 0px;
}
.trainingCards,.userTrainingCards {
    box-shadow: 0px 0px 1px -1px #caf0f8, 0px 1px 1px 0px #caf0f8, 0px 1px 3px 0px #caf0f8 !important;
}
.fa-long-arrow-alt-right:before {
    content: "\f2c1";
}
.userTrainingBadge
{
    border: 5px solid #CDB4DB;
    border-radius: 10px;
    box-shadow: 0px 0px 1px -1px #A2D2FF, 0px 1px 1px 0px #A2D2FF, 0px 1px 3px 0px #A2D2FF;
}
.trainingTableImageCard {
    height: 40px;
    width: 84px;
    margin: auto;
    background-color: transparent !important;
}
.tableImage {
    object-fit: contain !important;
    height: 59px !important;
    margin-top: -11px !important;
}
.trainingTableImageCard.MuiPaper-elevation1.MuiPaper-rounded {
    box-shadow: none;
}
.trainingCards .fa {
    color: #7554be !important;
}
#cardTooltip, #cardTooltip .container, #cardTooltip.MuiTooltip-tooltip {
    color : #480ca8 !important;
    background-color:  white !important;
    
}
#cardTooltip
{

    box-shadow: 0px 0px 1px -1px #caf0f8, 0px 1px 1px 0px #caf0f8, 0px 1px 3px 0px #caf0f8 !important;
}
#cardTooltip .MuiTooltip-tooltip {
    min-width: 400px !important;
    padding: 5px 8px;
    color : #4361EE !important;
    background-color:  white !important;
    
}
.tooltipHeader {
         background-color: #798ef221 !important;
         width: 112% !important;
         margin-left: -6% !important;
        }
.skillHeader {
    background-color: #798ef221 !important;
    margin-left: -14px;
    padding-left: 35px !important;
    border-bottom: 1px solid #480ca84a;
    border-right: 1px solid #480ca84a;
}
.skillBorder
{
    border-right: 1px solid #480ca84a;

}
.w_111
{
    width  :111% !important
}
.marginl-n13
{
    margin-left: -13px !important;
}


.expectationHeader {
    background-color: #798ef221 !important;
    padding-left: 75px !important;
    border-right: 1px solid #480ca84a;
    border-bottom: 1px solid #480ca84a;
}
.row.tooltipTitle {
    background-color: #480ca8;
    margin-top: -17px;
    width: 111%;
    margin-left: -20px;
    padding: 1px;
    color : white !important
}

#trainingDetailsModal .modal-header {
    color: #fcfcfa;
    text-shadow: 1px 1px #2E279D;
    background-color: #2E279D;
}
.cardName {
    color: #332bb1;
    font-size: 25px !important;
    text-shadow: 1px 1px #ebedf0;
}
.cardSkillsExpecationsHeader.MuiTypography-body1 {
    color: #4D80E4;
    font-size: 16px;
}

li.skillsData {
    font-size: 14px;
    color: #60c108;
    padding: 10px;
}

#trainingDetailsModal .modal-body
{
    height: 450px !important;
    overflow-y: scroll;
}
.badgeImg {
    height: 75px;
    background-color: transparent;
    margin-top: -11px;
}

.trainingConfluenceLink a {
        color: #7554be;
        font-size: 20px !important;
        padding-left: 0px !important;
}

.beginnerContainer
{
    border: 1px solid #80808040 !important;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
    background-color: white !important;
    border-radius: 6px !important; 
}

.footerContainer
{
    border: 1px solid #f1edf2 !important;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
    background-color: #7554bd0d !important;
    border-radius: 6px !important;
    width: 104% !important;

}
.badgeCard .MuiCardMedia-img {
    object-fit: contain;
    background-color: white !important;
}

.trainingCarousel .CarouselItem {
    background-color: transparent !important;
    margin: -49px 108px -20px 42px;
    height: auto !important;
    /* overflow-y: auto !important; */
}
.carousalCard.MuiPaper-elevation1.MuiPaper-rounded {
    display: inline-block;
    width: 200px !important;
    height: 200px !important;
}
.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content
{
    box-shadow: none !important;
}
.react-3d-carousel {
    margin-top: -65px !important;
    box-shadow: 0 0 10px #a59b9bb0;
    border-radius: 20px;
}
.react-3d-carousel .slider-container .slider-left div {
    color: #4a0ea96b !important;
    border-top: 2px solid #ffffff17 !important;
    border-right: 2px solid #ffffff17 !important;
    border-bottom: 2px solid #ffffff17 !important;
    border-left: 2px solid #ffffff17 !important;
    box-shadow: 0 0 10px #a59b9bb0;
    border-radius: 5px !important;
    left: -295% !important
}
.react-3d-carousel .slider-container .slider-right div {
    color: #4a0ea96b !important;
    border-top: 2px solid #ffffff17 !important;
    border-right: 2px solid #ffffff17 !important;
    border-bottom: 2px solid #ffffff17 !important;
    border-left: 2px solid #ffffff17 !important;
    box-shadow: 0 0 10px #a59b9bb0;
    border-radius: 5px !important;
    left: 295% !important
}