.no-messages {
    display: block;
    margin: auto;
    padding: 20px;
}

.list-style {
    list-style: none;
}

.mount-style {
    opacity: 1;
    transition: all 1s ease;
}

.unmount-style {
    opacity: 0;
    transition: all 1s ease;
}
.viewAllLink {
    background-color: white;
    color: #3f51b5;
    border: none;
}