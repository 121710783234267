.notification-box-item:hover {
  filter: brightness(96%);
}

.IconBtn:focus {
  outline: none;
}

.clickable {
  cursor: pointer;
}
.notificationTable
{
  width: 108% !important;
  margin-left: -39px !important;
}
.unreadRow
{
  background-color: #e0eaf1 !important
}
