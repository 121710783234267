.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

.metro .fa {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.page-header {
    padding-top: 10px;
    padding-bottom: 20px;
}

.nav-tabs .nav-item {
    cursor: pointer;
}

.img-hor-vert {
    -moz-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.container .white {
    background-color: #fffffff2;
    -moz-box-shadow: 0 0 10px #ffffffd6;
    -webkit-box-shadow: 0 0 10px#ffffffd6;
    box-shadow: 0 0 10px #ffffffd6;
    border-radius: 10px;
    padding: 30px;
    min-height: 330px;
}

.footer {
    margin-bottom: 60px;
}

.container .black {
    padding: 20px;
    border-radius: 10px;
    color: white;
    background-color:#1d1d1db0;
    -moz-box-shadow: 0 0 10px #1d1d1db0;
    -webkit-box-shadow: 0 0 10px#1d1d1db0;
    box-shadow: 0 0 10px #1d1d1db0;
    margin-left: 2px;
    width: 1333px;
}

.pagination {
    justify-content: center;
}

.pagination .page-item {
    margin: 0 !important;
    border: none !important;

}

.page-item.disabled .page-link {
    color: #6c757d !important;
    pointer-events: none !important;
    cursor: auto !important;
    background-color: #fff !important;
    border-color: #dee2e6 !important;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff !important;
    background-color: #fff !important;
    border: 1px solid #dee2e6 !important;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff !important;
    background-color: #007bff !important;
    border-color: #007bff !important;
}

.page-link:hover {
    z-index: 2;
    color: #0056b3 !important;
    text-decoration: none !important;
    background-color: #e9ecef !important;
    border-color: #dee2e6 !important;
}

dl, ol, ul {
    margin: 0 !important;
}

.list-group-item-heading {
    display: flex;
    justify-content: space-between;
}

.list-group-item.green.active {
    background-color: #28a745;
    border-color: #28a745;
    color: #fff;
}

.list-group-item.turquoise.active {
    border-color: #01a5a3;
}

.list-group {
    padding-bottom: 10px;
}

.list-group-item.red.active {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.box-gallery {
    z-index: -99999 !important;
}

.box-gallery .box-img {
    background-color: #fff !important;
}

.box-gallery .box-panel {
    position: fixed !important;
}
img {
    max-width : 200% !important
}
.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.td-icon.icon, .td-icon-xs.icon, .td-icon-sm.icon, .td-icon-md.icon, .td-icon-lg.icon, .td-icon-xl.icon {
    color: white !important; /* fix icon color on deployment */
}

.swal2-icon {
    font-size: unset !important;
}

.tdiRegisterBtn {
    background: linear-gradient(168deg,#8e397f -10%,rgba(135,55,124,0.25) 10%,rgba(229,195,222,0.15) 40%,rgba(229,195,222,0.1) 53%),linear-gradient(112deg,#87377c,#d40983 32.1%,#ff8d18 90%);
    padding: '0px';
    height: '40px'
}

.tdiRegisterBtn:hover {
    background: linear-gradient(168deg,#1a87d3 -10%,rgba(135,55,124,0.25) 10%,rgba(229,195,222,0.15) 40%,rgba(229,195,222,0.1) 53%),linear-gradient(112deg,#1f7e36,#266765 32.1%,#18ff34 90%)
}

#inputTextField {
    border: none;
    position: inherit;
    color: inherit;
    height: 40px;
}

.orderBuilder {
    margin-left: 430px;
}

.orderBuilder button{
    width: 200px;
}

.orderBuilder button[aria-pressed="true"]{
   color: #fff;
   background-color:#3f51b5;
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    /* width: 80px; */
    height: 15px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 10px;
    width: 13px;
    height: 13px;
    border-radius: 60%;
    background: #e83319;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    margin-left: 5px;
  }
  .lds-ellipsis div:nth-child(1) {
    left: 5px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 5px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 28px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  
 .bill_icon button:hover {
    background-color: rgb(255 0 0 / 21%) !important;
    border-radius: 17px !important;
}

.bill_period{
    padding-left: 30px !important;
}

.MuiFormLabel-asterisk {
    color: red;
}
.requestFill {
    width: 85%;
    margin-left: 50px;
}
.requestFileStatus td{
    width: 200px
}

.createRcr p{
    margin-top: 0px;
}

.createRcr h4{
    margin-bottom: 2px;
}

.createRcr .createRcrChild{
    border-left:6px solid #007bff;
}

.createRcrChild p {
    margin-top: 6px;
}

.string-value {
    cursor: pointer !important;
}

.magnifyButton{
    background-color: #007bff;
    /* background-color: #4ca44c; */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    animation: magnify 1s infinite;
}

@keyframes magnify {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}