

.ops-icon{
    color:#1ba1e2;
}

.btn.btn-upload:disabled {
    opacity: 0.6;
    box-shadow : none;
    background : none !important;
     border : none !important;
      color : "#1ba1e2";
}

.btn.btn-upload ,
.btn.btn-upload:hover,
.btn.btn-upload:focus,
.btn.btn-upload:visited{
    box-shadow:0px 0px 3px 0px #1ba1e2;
    background : none !important;
    border : none !important;
     color : "#1ba1e2";
     opacity :1;
}

.requestFilterLabel {
    font-weight: bold;
    display: block;
    width: 100%;
    height: calc(1em + .75rem + 2px);
}



