/* The slider itself */

.tooltip.tooltip-main {
  opacity: 0.6 !important;
  top: -140%;
  margin-left: -19px;
  width: 18%;
 
}


.tooltip-inner {
  font-size: 80%;
  
}
.slider .tooltip.top {
  padding: 3px 2px 0px 2px;
}
.tooltip.top .tooltip-arrow {
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip-arrow {
  position: absolute;
  width: 10;
  height: 0;
  border: solid transparent;
}

.survey-result { 
  display: flex;
  align-items: center;
}
.survey-result .fa-handshake-o {
  color: white;
  opacity: 1 !important;
}
.lineHeightRanking {
  min-height: 0px !important
}
.tdmFont {

  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif, "Helvetica Neue", Arial, sans-serif;

}
#quantativeAccordian.MuiExpansionPanel-root {
  min-height: 70px !important;
    width: 99%;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
#quantativeAccordian .makeStyles-avatar-509 {
  width: 53px;
  height: 50px;
  background-color: #3949ab;
}
#quantativeAccordian .MuiExpansionPanelSummary-content {
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: -16px;
}
#quantativeAccordian .MuiExpansionPanelSummary-expandIcon {
  margin-top: 2px;
  color: white !important;
}
#quantativeAccordian .MuiTableCell-stickyHeader{
  background-color: #c4cff7 !important
}
#quantativeAccordian .MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 3.5;
  letter-spacing: 0.00938em;
  padding-top: 3px;
  color: rgba(0, 0, 0, 0.54)
}
#quantativeAccordian .MuiTableCell-root {
  border-bottom: 2px solid rgb(236 235 235) !important;
}
.usersAccordian p.MuiTypography-root.MuiTypography-body1 {
  color: #757575;
  font-size: 18px;
}
.card-box
{
  border: 1px solid rgba(122, 123, 151, 0.3);
}
.mb-spacing-6-x2 {
  margin-bottom: 48px;
}
.card-header {
  border-width: 1px;
  border-color: rgba(7, 9, 25, 0.125);
}
.card-header:first-child {
  border-radius: 0.65rem 0.65rem 0 0;
}
.p-4 {
  padding: 1.5rem !important;
}

.align-items-center {
  align-items: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgb(241 236 255 / 33%) ;
  border-bottom: 0 solid rgba(122, 123, 151, 0.3);
}
.divider {
  background: #eeeff8;
  height: 1px;
  overflow: hidden;
}
.headerDivider {
  background-color: rgb(26, 179, 148);
  height: 3px;
  overflow: hidden;
}
.headerRows {
  background-color: transparent;
}
.leadingIcon svg.MuiSvgIcon-root {
  fill : #006400 !important;
  margin-left:10px !important
}
.optimisedIcon svg.MuiSvgIcon-root {
  fill : #32CD32 !important;
  margin-left:20px !important
}
.consistentIcon svg.MuiSvgIcon-root {
  fill : #ffdb58 !important;
  margin-left:25px !important
}
.repeatableIcon svg.MuiSvgIcon-root {
  fill : #FFA500 !important;
  margin-left:30px !important
}
.adhocIcon svg.MuiSvgIcon-root {
  fill : #FF0000 !important;
}
.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}
.pasmatHeader {
  background-color: #3b4ba0;
  color: white !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 75px !important;
}


.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}
.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}
.pt-3, .py-3 {
  padding-top: 1rem !important;
}
.mt-3, .my-3 {
    margin-top: 1rem !important;
}
.table.table-alternate-spaced {
  border: 0;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-medium {
  font-weight: 300 !important
}
.font-size-md {
  font-size: 17px ;
}
.text-grey {
  color: #5f5e94f2 !important
}
.text-capitalize {
  text-transform: capitalize !important;
}

.table thead th {
  text-transform: uppercase;
  background: #f4f5fd;
  font-size: 0.875rem;
  vertical-align: middle;
}
.text-left {
  text-align: left !important;
}
.table.table-alternate-spaced tbody tr {
  background: transparent;
}
.table tr {
  transition: all 0.2s ease-in-out;
}
.table.table-alternate-spaced tbody tr td {
  background: transparent;
  position: relative;
}

#quantativeAccordian .MuiCard-root {
  width: 97% !important;
  box-shadow: none !important;
}
.table.table-alternate-spaced tbody td {
  border: 0;
}
.table.table-alternate-spaced thead th {
  background: whitesmoke !important;
  font-weight: bold;
  background: none;
  font-size: 0.79545rem;
  border: 0;
  padding-top: .625rem !important;
  padding-bottom: 0.625rem;
}
card-box .card-footer, .card-box .card-header {
  border-width: 1px;
  border-color: rgba(7, 9, 25, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 0.65rem 0.65rem;
}
.card-footer:last-child {
  border-radius: 0 0 0.65rem 0.65rem;
}
.p-4 {
  padding: 1.5rem !important;
}
.thead-light{
border-bottom: 2px solid #8ec5f5 !important

}
.table.table-alternate-spaced tbody tr td {
  border-bottom: 1px solid #cee0e8 !important;
  padding-bottom: 2px;
  padding-top: 5px;
}
.trendingDown svg.MuiSvgIcon-root {
  fill: #4191ff !important;
  margin-right: 15px !important;
  border-radius: 50rem !important;
  background-color: #eaf6ff !important;
  height: 30px;
  width: 30px !important;
}
.trendingUp svg.MuiSvgIcon-root
{
  fill: #1bc943 !important;
  margin-right: 15px !important;
  border-radius: 50rem !important;
  background-color: #e5f9ed !important;
  height: 30px;
  width: 30px !important;
}
.table.table-alternate-spaced tbody tr {
  background: transparent;
  font-size: small;
  color: grey;
}
.sv_q_text_root {
  display: none !important;
}

.servDescRow{
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 175px;
  display: inline-block;
  margin-bottom: -7px !important;
  padding-right: 0px !important;
  line-height: 30px;
}

.qReportPanel
{
    background-color: white;
    width: 100% !important;
    flex-grow: 1;
    box-shadow: 0 3px 7px 2px rgb(64 64 64 / 30%);
    border-radius: 20px;
    padding: 25px !important
}
.reportButton
{
  float: right;
    margin-top: -60px !important;
    margin-right: 120px !important;
}
.reportCloseIcon {
    position: sticky !important;
    margin-left: -6px !important;
    z-index: 9999 !important;
    margin-bottom: -62px !important;
}
.clickTypo
{
  color: #1838d2 !important;
  height: 17px !important;
  margin-top: -15px !important;
  margin-left: 1px !important;
  font-size: 11px !important;
}

/* css for N/a  slider begins here */
.sv_q_matrix tbody {
  display: none;
}
table.sv_q_matrix {
  margin-left: -200px !important;
}
.sv_q_matrix tr
{
  border :0px !important
}
/* css for N/a  slider ends here */

.viewReportButton
{
  float: right;
  margin-right: -10px !important;
}
.nextButton {
  float: right;
    margin-top: 50px !important;
    margin-right: 2px !important;
}
.nextButton:disabled {
  display: none !important;
}
.prevButton {
  top: 4em;
}
.prevButton.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: transparent;
  color: transparent !important;
}

* {
    box-sizing: border-box;
}

#progress {
    padding: 0;
    list-style-type: none;
    font-family: arial;
    font-size: 12px;
    clear: both;
    line-height: 2em;
    margin: 0 -1px;
    text-align: center;
}

#progress li {
    float: left;
    padding: 10px 27px 10px 40px;
    background: #3b4ba0;
    color: #fff;
    position: relative;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
    width: 16%;
    margin: 0 1px;
}

#progress li:before {
  content: '';
  border-left: 25px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
    
}
#progress li:after {
  content: '';
  border-left: 25px solid #3b4ba0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 20;
}

#progress li.active {
    background: #1c2557
}

#progress li.active:after {
    border-left-color: #1c2557;
}
.reportRowWidth
{
  width: 109% !important;
  font-size:17px !important
}
.pl50
{
  padding-left:77px !important
}
.feedbackSubmit{
  float: right;
    padding-bottom: 1px;
    margin-top: -5px;
}
.parentProgram {
  width: 97%;
  background-color: white;
  border-radius: 5px;
}
.parentProgram .MuiInputBase-input {
  margin-left: 5px;
}
.parentProgram .MuiInputBase-input:focus {
  margin-left: 5px;
  box-shadow: none !important;
}
input#parentProgram ,input#teamsDropdown {
  height: 20px !important;
}
.pasmatMetricCard
{
  background: transparent !important;
}
.scatter-point {
  stroke: rgb(0, 157, 249);
  stroke-width: 15px;
  fill-opacity: 0.25;
  fill: rgb(0, 157, 249);
}
.treeMap{
  width: 100%;
  margin: 10px 0px 10px 70px !important;
}
#surveyTab.MuiAppBar-colorPrimary {
  background-color: white !important;
}
.surveyPagination{
  top : 55px !important
}
.MuiPaginationItem-page.Mui-selected:hover, .MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
  background-color: rgb(59 75 160 / 74%);
}
.MuiPaginationItem-page:hover {
  background-color: rgb(63 81 181 / 33%);
}
.MuiPaginationItem-icon {
  margin: 0 -8px;
  font-size: 1.25rem;
  color: #3f51b5;
}
.MuiPaginationItem-root {
  color: rgb(63 81 181) !important;
}
.effortCol {
  margin-left: 55px;
}
.pasmatHeaderText
{
    font-size: 18px;
    color: #6e7171;
    padding: 11px 10px 14px 11px;
    font-weight: 500;
}
