#ddcTable {
    background-color: #e1f4fa38;
    border-radius: 5px;
    padding: 2px;
}
#ddcTable.table-hover tbody tr:hover {
    color: #232528;
    background-color: #d7e8f11a;
}
#ddcTable .form-control:disabled, #ddcTable .form-control[readonly] {
    background-color: #ffffff;
    opacity: 1;
    border: #d7d0d0 1px solid;
}
#deleteCol
{
    background-color: transparent;
    padding-left: 0px;
    margin-left: -3%;
    color: #5cb9e8;
}
#reviewButtons
{
    background-color: transparent;
    padding-left: 0px;
    color: #5cb9e8;
}
.reviewPanel {
    margin-left: 6px !important;
    margin-top: 20px;
    background-color: #336c990f;
    padding-left: 20px;
    padding-right: 20px;
}
#reviewButtons:focus {
    outline: none;
}


#updateButtons, #updateButtons:focus , #updateAssetButtons, #updateAssetButtons:focus {
    float: right;
    font-size: 15px;
    margin-left: 12px;
    margin-right: 0px;
    padding-bottom: 24px;
    /* background-color: #1a90ca !important; */
    color: #5cb9e8 !important;
    margin-top: -36px;
    outline : 0px !important
}
#updateAssetButtons, #updateAssetButtons:focus
{
    margin-top: 11px;
    height: 20px !important;
    margin-right: -4px;

}
.tableTextField .MuiOutlinedInput-root .MuiOutlinedInput-input , .tableTextFieldError .MuiOutlinedInput-root .MuiOutlinedInput-input{
    padding: 12.5px 14px !important;
}
.tableTextFieldError {
   min-height : 85px 
}
.tableTextFieldError .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border: 1.3px solid #0e0f103d;
}
.reviewInputField{
    min-width: 232px !important;
}
.reviewInputField .MuiOutlinedInput-input {
    padding: 12.5px 14px;
}
.reviewInputField .MuiInputBase-root.Mui-disabled {
    /* color: rgb(10 22 32); */
    cursor: default;
    background-color:#dae8f1;
}
.reviewInputField .MuiInputBase-input.Mui-disabled {
    opacity: 1;
    color:#2494cc !important
}
#reviewButtons .MuiButton-label
{
    font-size: 15px;
    font-weight: 700;
}
#billingPanel {
    /* padding-right: 20px !important; */
    /* background-color: #366b950f; */
    border-right: 5px solid white;
}
#billingPanel ,#assetsPanel {
    /* padding-right: 20px !important; */
    /* background-color: #366b950f; */
    padding-bottom: 50px;
}
.reviewInputField .MuiInputLabel-outlined {
    background-color: transparent !important;
    font-size: 10px !important;
}
#ddcTable .MuiButton-contained.Mui-disabled {
    background-color: #dae8f1;
    color: #00000038 !important;
}
.rowHeader {
    background-color: #dae8f1;
    padding: 10px;
}