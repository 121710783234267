.MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(3px, -14px) scale(1) !important;
    pointer-events: none;
    background-color: #f6f7f7
}

.cosmosJson
{
    height: 800px !important;
    overflow: scroll;
    width: 150% !important;
    background-color: #ecf0f1cc;
    border-radius: 10px;
}
#iconButton
{
    font-size: 50px !important
}
.cosmosRow {
    margin-right: -450px !important;
}