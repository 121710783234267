.nav-glass {
    background-color:#1d1d1db0;
    -moz-box-shadow: 0 0 10px #1d1d1db0;
    -webkit-box-shadow: 0 0 10px#1d1d1db0;
    box-shadow: 0 0 10px #1d1d1db0;
}

.navbar-brand {
    font-size: 1.5rem;
}

.nav-thin {
    height: 75px;
}
.dashboardHeaderIcon {
    min-width: 125px;
    display: inline-block;
    margin-top: -5px;
    margin-left: -539px;
    padding: 0px 15px;
}