.landing-box {
    padding: 20px;
    border-radius: 10px;
    color: white;
    margin-bottom: 30px;
}

.nav-centered {
    width: 100%;
    max-width: 100%;
    justify-content: space-evenly;
    font-size: 1.2em;
}

.td-icon-sm {
    font-size: 3rem !important;
}

.icon-link {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.nav-link,
.nav-link>a,
.nav-link>i {
    background-clip: text !important;
    -webkit-background-clip: text !important;
}

.nav-link>a,
.nav-link>i {
    color: transparent !important;
}

.nav-link>i {
    position: static;
}

.nav-link>span {
    color: #fff;
    text-align: center;
    width: 100%;
    white-space: nowrap;
}

.nav-link>span>div {
    overflow: hidden;
    text-overflow: ellipsis;
}

.landing-box.nav-glass .nav-item {
    width: 100%;
    overflow: hidden;
}

.nav-item a:hover {
    text-decoration: none;
}

.nav-item:hover {
    background-color: #00000036;
    border-radius: 5px;
}

.tile-box {
    display: inline-flex;
    flex-wrap: wrap;
    position: sticky;
}

.tiles-grid.flex {
    display: flex;
    justify-content: space-evenly;
}

/* p {
    color: #e8e8e8;
} */

.snap-parent {
    scroll-snap-type: y mandatory;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.snap-parent-box {
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.snap-child {
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
}

.container::-webkit-scrollbar {
    display: none;
}