.content {
    margin: 20px 30px 15px 30px;
}

.pageBtn {
    margin-top: 15px;
}

.pageBtns {
    display: flex;
    justify-content: space-between;
}

.FormWrapper {
    padding: 40px 40px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.MuiChip-root {
    width: auto;
}

.MuiTableCell-head, .MuiTypography-h6 {
    font-weight: bold !important;
}