.dataSetHeader {
    background-color: #5849b7 !important}
.dataSetHeader th.MuiTableCell-root{
    font-size: 16px;
    font-weight: 600;
    color : white !important;
    border: 1px solid white !important;
}
td.MuiTableCell-root.MuiTableCell-body.datadesc{
    width: 225px !important;
}

.dataSetBody .MuiTableCell-body {
    color: rgba(0, 0, 0, 0.87);
    border-left: 1px solid #faf3f3;
}

.dataActionButton {
    background-color: #5849b7 !important;
    color: white !important;
    min-width: 108px !important;
    border: 1px solid #5849b7 !important;
    border-radius: 4px;
}
.dataActionButton.MuiButton-root:hover {
    text-decoration: none;
    background-color: #5849b7 !important;
    color: white !important;
}
#retrieveDataTable {
    border-radius: 3px !important;
}

.userSecretIcon {

    color: #e01b11;
    font-size: 20px;
}
.teamIcon{
    color: #248134;
    font-size: 20px;
}
.userIcon{
    color: #5849b7;
    font-size: 20px;
}
#retrieveModal .modal-header {
    background-color: #5849b7 !important;
    color: white !important;
}
#qtySelected-label {
    background-color: white !important;
}
.envSelect
{
    min-width: 223px;
}
.checkinContainer.container{
    background-color: #f4f4f4b8 !important;
    padding: 35px 0px 30px 0px !important;
    border: 1px dotted #d9dade !important;
    border-radius: 5px !important;
    outline: 1px solid #d9dade;
    margin-bottom: 20px !important;
}
.metaDataContainer.container{
    padding: 35px 0px 30px 0px !important;
    border-radius: 5px !important;
    margin-bottom: 20px !important;
    outline: 1px solid #ede9f0;
    background-color: #fcfbffad !important;
}
.metaDataCheckoutContainer.container{
   
}
.filterDataContainer.container{
    padding: 35px 0px 30px 0px !important;
    border-radius: 5px !important;
    margin-bottom: 20px !important;
    outline: 1px solid #ede9f0;
    background-color: white !important;
}
.metaDataLabel .MuiTypography-body1 {
    font-size: 17px;
    font-weight: 600;
    color: #607b82;
    text-shadow: 0 0 #d6e9f0ad;
}
.metaDataValue .MuiTypography-body1 {
    font-size: 17px;
    font-weight: 600;
    color: #2aa198;
    text-shadow: 0 0 #d6e9f0ad;
}
.checkCircle {
    color: #079707;
    font-size: 35px !important;
}
.checkCancel {
    color: #c71d06;
    font-size: 35px !important;
}

.filterNameLabel .MuiInputBase-input.Mui-disabled ,
.filterValueLabel .MuiInputBase-input.Mui-disabled ,
.checkoutFilterLabel .MuiInputBase-input.Mui-disabled{
    opacity: 1;
    color: #6471b9c2 !important;
}
.addCircleTwoToneIcon {
    color: #0c8d2d !important;
    font-size: 30px !important;
}
.cancelIcon {
    color: #ec0707 !important;
    font-size: 30px !important;
}
.filterHeaderIcon
{
    color: #3db91e !important;
    font-size: 40px !important;
    
}
.filterSpace {
    width: 555px !important;
    display: inline-block !important;
}
.checkoutFilterLabel {
        min-width: 200px !important;
    }
.checkinFilterNameLabel ,.errorFilterLabel{
    min-width: 300px!important
}
.checkinFilterValueLabel{
    min-width: 300px!important
}
.checkinFilterNameLabel .MuiFormLabel-root , .checkinFilterValueLabel .MuiFormLabel-root
.errorFilterLabel .MuiFormLabel-root {
    background-color: white !important;
}
.errorFilterLabel .MuiInputBase-input.Mui-disabled {
    opacity: 1;
    border: 2px solid red;
    border-radius: 5px;
    color : red !important
}
.colHeader {
    color: #6c9376;
    padding: 25px 0px 25px 11px;
    font-size: 20px;
}
.errorHeader {
    color: #e91414;
    padding: 25px 0px 25px 11px;
    font-size: 20px;
}
.addedFilterHeader {
    color: #6c9376;
    padding: 25px 0px 25px 11px;
    font-size: 20px;
}
.checkinFilterNameLabel .MuiInputBase-input.Mui-disabled {
    opacity: 1;
    color: #67608f !important;
}
.datasetFilterPopup .MuiDialog-paperWidthSm {
    max-width: 900px !important;
    min-width: 900px !important;
    min-height: 413px !important;
    overflow-x: hidden !important;
}
div#retrieveModal {
    min-height: 200px !important;
    max-height: 400px !important;
    overflow-y: scroll;
}
.datasetFilterPopup .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 3px 10px 0px 20px;
    background: #5849b7;
    color: white;
    height: 62px;
}
.datasetFilterPopup .closeIcon .MuiSvgIcon-colorAction {
    color: white;
}
.datasetFilterPopup .filterNameLabel {
    min-width: 254px !important;
    margin-left: 5px;
}
.jsonGrid
{
    background-color: #f4f4f4b8 !important;
    padding: 35px 0px 30px 0px !important;
    border: 1px dotted #d9dade !important;
    border-radius: 5px !important;
    outline: 1px solid #d9dade;
}
.imageCol
{
    margin-left: 10px;
}
.editorBackground
{
    background: white !important;
}
.fileLabel
{
    display: none !important;
}
.checkinDocsRow
{
    border-top: 1px rgb(223, 221, 221) solid;
}
.checkinButton {
    margin-left: 43px !important;
}
.addJsonHeader {
    color: #3f51b5;
    font-size: 20px;
    font-family: system-ui;
}
.containerHeader {
    font-size: 15px;
    color: #3f51b5;
}

#qtySelected-helper-text {
    margin-left: 0px;
}
.MuiDialog-root.datasetFilterPopup {
    z-index: 1059 !important;
}

.checkinActions {
    color: grey;
    font-family: system-ui;
    font-family: system-ui;
}
.customHeaders {
    color: #5849b7;
    font-size: 13px;
}
.addJson,.addJsonDisabled,.addTagsIcon,.addFiltersIcon,.validateCheck, .validCheck, .inValidCheck,.beautifyIcon, .jsonUpload, .excelUpload
{
    background-color: #3f51b5;
    color: white;
    border-radius: 50%;
    width: 10% !important;
    height: 80% !important;
    padding: 5px !important;
}
.addJson
{
    background-color: #712271;
}
.addJsonDisabled
{
    background-color: grey !important;
}
.addTagsIcon
{
    background-color: orange;
}
.addFiltersIcon
{
    background-color: #dc4fad;
}
.validCheck
{
    background-color: #3db91e !important;
}
.inValidCheck
{
    background-color: red !important;
}
.beautifyIcon
{
    background-color: cornflowerblue;
}
.jsonUpload
{
    background-color: #0089ff;
}
.excelUpload
{
    background-color: green !important;
}
.filterContainer{
    border-radius: 5px;
    margin-left: -10px !important;
}
.MuiChip-root {
    color: white !important;
    background-color: #348d60 !important
}
.customQtyHeader {
    color: #5849b7;
    font-size: 15px;
    font-weight: 700;
}
.customQtyErrorHeader
{
    color: red;
    font-size: 15px;
    font-weight: 700;
}
input#customdataFiltersAutoComplete {
    height: 5px;
}
/* .customAutoComplete .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    min-width: 250px !important;
} */
.filterValueLabel input.MuiInputBase-input.MuiOutlinedInput-input , .filterNameLabel input.MuiInputBase-input.MuiOutlinedInput-input {
    height: 5px;
}
.customDataNameHeader
{
    font-size: 15px;
    font-weight: 700;
    max-width: 150px;
    width:100%;
    color: #5849b7;
}
.qtyText .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    height: 40px !important;
}

.checkoutModal
{
    color:#5849b7 !important
}
.checkoutModal.modal-header button.close {
    color: #5849b7 !important;
    text-shadow: none !important;
    opacity: 1 !important;
}
.MuiFormControl-root.MuiTextField-root.filterAutoCompleteText.MuiFormControl-fullWidth {
    width: 200px;
}
.filterAutoCompleteText input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
    padding: 10px;
}
.MuiFormControl-root.MuiTextField-root.filterAutoCompleteText {
    border-radius: 7px;
}
.filterAutoCompleteText .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    padding-right: 0px !important;
}
.filterAutoCompleteText button.MuiButtonBase-root.MuiIconButton-root:focus {
    outline: none !important;
}
.filterAutoCompleteText .MuiOutlinedInput-notchedOutline {
    height: 50px;
    margin-top: 5px;
    border: 1px outset #7554be;
}
.filterClassEmpty {
    display : none !important
}
.selectTeamClass{
    width: 200px;
    margin-left: 11px;
}

.selectTeamHeadClass{
    margin-left: 10px;
    margin-bottom: 10px;
    color: #5849b7 !important;
    font-size: 13px !important;
}

.MuiGrid-container h6{
    color: #5849b7 !important;
    font-size: 13px !important;
    font-weight: normal !important;
    margin-left: 10px !important;

}

.formTextArea{
    margin-left: 10px !important;
}

.swal2-footer {
    display: none !important;
}