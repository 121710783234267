.filter-bar{
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}
.date-label{
    margin-right: 5px;
}

.datepicker{
    z-index: 1;
}

.datepicker input {
    font-size: 13px !important;
}

.button-space{
    margin-left: 15px;
}
.custom{
    width: 8rem;
    height: 90%;
}
.custom-dropdown{
    float: right;
    width: 80%;
}

.payment-row{
    margin-left: 0px;
    margin-right: 20px;
}

.heading{
    margin-top: 20px;
    margin-bottom: 20px;

}
.payment-history{
}

.payment-history-block{
    background-color: azure;
    padding: 20px;
    border: solid;
}

.search-icon{
    float: right;
}

.payments-message{
    text-align: center;
}

.react-datepicker-popper {
}

/* .SingleDatePicker_picker {
} */

.custom-size{
    font-size: 0rem !important;
}

.end-date{
    margin-left: 20px;
}