.content {
    margin: 20px 30px 15px 30px;
}

.pageBtn {
    margin-top: 15px;
}

.pageBtns {
    display: flex;
    justify-content: space-between;
}

#expandInfoBtn {
    margin: 0 auto;
    outline: none;
}

.simFormWrapper {
    padding: 15px 40px 15px 40px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.reviewFormWrapper {
    padding: 10px 40px;
    margin-bottom: 15px;
}

.msisdnFormWrapper {
    padding: 15px 40px 15px 40px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.simEditSectionWrapper {
    padding: 25px 40px 15px 40px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.simImsiRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.simImsiColumn {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding: 0px 15px;
}

.simImsiBody {
    max-height: 538px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.savePhysicalSimBtn {
    margin: 0px auto;
}

.optionalSpanText {
    color: lightslategray;
    font-style: italic;
    font-size: small;
}