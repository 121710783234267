#utility-alert-text p{
    line-height: 1.25;
    color: black;
}

.utility-input-area{
    padding: 1rem;
    background-color: hsla(0,0%,82.7%,.18);
    border-radius: 10px;
    margin: 10px 10px;
}
.utility-input-area .dataview-header p {
    font-size: 1.4rem;
}
.utility-input-heading{
    padding: 0.8rem 0;
    font-size: 1.2rem;
}
.utility-input-area:first-child{
    margin-top: 0px;
}

.utility-input-area-content{
    padding: 0 15px;
}

#utility-response>p{
padding: 10px;
}

#utility-response>p>strong{
    font-weight: 800;
    }

.utility-response-content{
    padding: 10px;
    border-radius: 5px;
    background-color: 	#F0F0F0;
    font-size: 1rem;
    margin: 5px;
}
.utility-response-content>p{
    padding: 5px;
    margin: 0;
}

.utility-response-content>p>strong{
    font-weight: 800;
}


.utility-Failed{
    background: #d26c661f;
}
div[class*="utility-Not"]{
    background: #fff00024;
}
.utility-Executed{
    background: #00800026
}

.utility-Failed #status{
    color: #ce352c;
}
div[class*="utility-Not"] #status {
   color: #ff9900;
}
.utility-Executed #status {
    color: green;
}

.utility-Success{
    background: #00800026;
}
.utility-Fail{
    background: #d26c661f;
}

.utility-Success #status {
    color: green;
}
.utility-Fail #status {
    color: #ce352c;
 }

 .eligibilityBtn{
    margin-top: 18px !important;
    height: 46px;
 }