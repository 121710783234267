.plus-btn {
    color: green !important;
    background: none !important;
    box-shadow: 0px 0px 3px 0px green !important;
}

.remove-btn {
    color: red !important;
    background: none !important;
    box-shadow: 0px 0px 3px 0px red !important;
}

.btn-submit {
    color: green !important;
    background: none !important;
    box-shadow: 0px 0px 3px 0px red !important;
}

.charmInsert h1 {
    font-size: 2rem;
}

.charmInsert .MuiFormControl-root {
    margin-left: 5px;
    margin-top: 18px !important;
}

.addAttributeBtn button {
    margin-top: 20px;
    margin-left: -35px;
}

.attributeTable table{
    margin-top: 115px;
    margin-bottom: 30px;
}

.attributeTable .remove-btn{
    margin-bottom : 15px !important;
}