.MuiBadge-badge {
   margin-top: 40px;
   margin-left: -45px;

}
.badgeCard {
    width: 60px !important;
    background-color: transparent !important;
    box-shadow: 0 0 10px #eae3e3b0 !important;
}

#badgeTooltip .MuiTooltip-tooltip {
   min-width: 100px !important;
   color : white !important;
   background-color:  transparent !important;
   font-size: 20px !important
   
}
.container.carousalContainer {
   padding: 70px 0 70px 0;
}
