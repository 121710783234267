.tcon-dv-row.active {
    background-color: #c7c7c73a;
    border: 2px solid rgba(189, 189, 189, 0.582);
    border-right-width: 0px;
    border-left-width: 0px;
}

.tcon-dv-row {
    cursor: pointer;
}

.tconnect-contact-table-wrap>div {
    max-height: 480px;
}

/* THESE STYLING ARE TEMPORARY AND ARE FOR BASIC ALIGNMENT OF TCONNECT INFO WARNING */

.tconnect-fm-info-cont>div>span i {
    font-size: 6rem;
}

.tconnect-fm-info-cont>div>div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.tconnect-datahuborder-table {
    table-layout: fixed;
}
.tconnect-datahuborder-table th,
.tconnect-datahuborder-table td
{
    overflow: hidden;
    font-size: 91%;
}