#date-range-container {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 98%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: inline-flex;
    -webkit-flex-wrap: none;
    -ms-flex-wrap: none;
    flex-wrap: none;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 37px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
}

.table th, .table td {
    padding: .75rem 0px .75rem 1px;
}

#date-range-container table td,
#date-range-container table th {
    padding: 0 !important;
    vertical-align: middle;
    border-top: 1px solid #e6e3de;
    margin-right: auto;
}

#date-range-container input  {
    border: 0;    
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

#date-range-container .DateInput__small {
    width: 87px;
}

#date-range-container .DateRangePickerInput{
    display: inline-flex;
     text-align: center;
     justify-content: center;
}
.DayPicker_portal__horizontal
{
    left : 59% !important
}