#userFeedbackDial button.MuiButtonBase-root.MuiFab-root.MuiFab-circular.MuiFab-sizeLarge.MuiFab-primary.MuiSpeedDial-fab.css-3mwa8g-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
    background: #2108a9d9;
    bottom: 30px !important;
    position: fixed !important;
}
#userFeedbackDialFeedback button.MuiButtonBase-root.MuiFab-root.MuiFab-circular.MuiFab-sizeLarge.MuiFab-primary.MuiSpeedDial-fab.css-3mwa8g-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
    color: #2108a9d9;
    bottom: 250px !important;
    position: fixed !important;
    background-color: white;
}

#userFeedbackDial.css-4reedn-MuiSpeedDial-root .MuiSpeedDial-actions
{
    bottom: 70px !important;
    position: fixed !important;
}
#userFeedbackDialFeedback.css-4reedn-MuiSpeedDial-root .MuiSpeedDial-actions
{
    bottom: 310px !important;
    position: fixed !important;
}
#feedbackText {
    min-width: 500px !important;
}
#feedbackText .modal-header {
    background-color: #3f51b5f7 !important;
    color: white;
}

#feedback-comment
{
    margin-bottom: 5px;
}