.ssoForm-signin{
    width: 100%;
    max-width: 600px;
    padding: 15px;
    margin: 0 auto;
  }
  .ssoForm-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }

  .textRight{
      text-align: right;
  }

  .fieldDiv{
    text-align: "justify";
    padding: "8px";
  }

  .sso-signin-btn{
    color: #fff !important;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}
.collapse-href
{
  cursor : pointer;
  color : #0366d6 !important;
   padding-right : 8px;
}
.muiSelectLabel{
  text-align: left;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.54);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

body.swal2-shown.swal2-height-auto {
  height: inherit !important;
}

.loading {
  display: block; 
  height: 240px; 
  width: 300px; 
  margin: auto; 
  padding-top: 20px; 
  border-radius: 20px; 
  background-color: rgba(255,255,255,0.9); 
  text-align: center;
}