
.main-card {
  box-shadow: -1px 0px 20px -2px rgba(0, 0, 0, 0.15);
  height: 20rem;
}
#address-row {
  font-family: inherit !important;
  background-repeat: no-repeat;
  background-position: right;
}

#address-row:before {
  content: "";
  background-image: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}


.card-heading {
  font-size: xx-large;
}

.u2p-collapse {
  height: 60px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background-image: linear-gradient(
    to bottom,
    #14a6e2 0%,
    #0d71a2 100%
  ) !important;
  border-color: whitesmoke !important;
  transition: 0.8s;
}



.account-details {
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.btn-success.focus,
.btn-success:focus {
  box-shadow: none !important;
}
.my-account-detail {
  font-family: TelstraAkkuratWeb-Regular, sans-serif !important;
  margin-left: 0 !important;
  margin-right: 0 !important; /*background-color: #1486bd;*/
  background-image: linear-gradient(
    to bottom,
    #14a6e2 0%,
    #0d71a2 100%
  ) !important;
  text-align: -webkit-auto;
  margin-top: 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
}


.box-inside-card-col {
  height: 120px;
  width: 100px;
  padding-top: 20px;
  background-color: yellowgreen;
}

.box-inside-card-row {
  display: block;
  margin: 0;
  vertical-align: top;
}

.company-details {
  padding-top: 20px;
  display: inline-block;
  min-width: 60%;
  vertical-align: top;
}

.rou-code {
  margin-top: 20px;
  padding-left: 25px;
}

.account-details {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: smaller !important;
}


.main-component {
  padding-left: 5%;
  padding-right: 5%;
}

.inner-block {
  display: block !important;
  margin: 0 !important;
  position: relative;
  max-width: 100%;
}
.date-block {
  display: block;
  margin: 0;
  max-width: 40%;
}
.small-box {
  display: inline-block;
  margin: 0;
  padding: 0;
  max-width: 40%;
  padding: 20px 0 0;

  vertical-align: top;
}
.zero-padding {
  padding: 0px;
  margin: auto;
}
.custom-icon-size {
  font-size: 4rem !important;
}
.icon-block {
  display: inline-block;
  vertical-align: text-top;
  margin-bottom: 15px;
  min-width: 45px;
}
.adjustment-body {
  height: 21rem;
  overflow-y: auto;
}
.card-header.adjustment-header {
  padding: 0px;
}
.base-blue.featured-gradient.adjustment-tile-size {
  width: 240px;
  height: 210px;
}

.card-body.adjustment-cardbody {
  float: left;
  width: 250px;
  padding: 1.25rem 0px 0px 1.25rem !important;
}
i.fa.fa-plus-circle.addicon {
  font-size: -webkit-xxx-large;
}
.custom-buttom {
  min-width: 8rem;
}

.popupcontent {
  width: 130%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  right: 22%;
}
.u2c-header {
  margin: 0px;
}
.switch-address-button {
  padding-left: inherit;
}

.custom-header {
  margin-left: 70px;
  margin-right: 44px;
}

.new-line {
  margin-bottom: 5px;
}
.loading-failed {
  margin-left: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.top-line{
  margin-top: 20px;
}
.bottoon-line{
  margin-bottom: 20px;
  margin-top: -5px;
}
.collapse
{
  overflow: initial !important
}
