.notificationFeatureHeader {
    text-align: center;
    color: #0d4e8e;
    padding-top: 10px;
}
#msgDialog .Carousel-buttonWrapper-11:hover .Carousel-button-16 {
    filter: drop-shadow(2px 4px 6px black) !important;
    opacity: 0.2 !important;
    background-color: #2d45b9 !important;
}
.notifyHeader {
    /* padding: 20px !important; */
    margin-bottom: 10px;
    color: #376f6f;
    font-size: 18px;
    font-weight: 900;
}
.msgCard
{
    box-shadow: none !important;
    margin-top: 15px;
    margin-bottom: 2px;
}
.notifyDescription a:not([class]) {
    color: #4d9796 !important;
}
.notifyDescription
{
    padding-top: 20px;
    padding-left: 25px;
    color: #0e5091;
    max-width: 438px !important;
}
#msgDialog .MuiDialog-paperWidthXl {
    max-width: 1920px;
    width: 520px !important;
    opacity: 0.95;
    min-height: 380px !important;
    border-radius: 15px !important;
}
.msgMedia.MuiCardMedia-img {
    object-fit: contain !important;
    /* box-shadow: none; */
    height: 90px !important;
    /* margin: 0px 10px 0px 18px; */
}
.CarouselItem {
    /* background-color: lavender; */
    margin: 0px 60px 0px 60px;
    height: 187px !important;
    /* overflow-y: auto !important; */
}
.notificationCarousel button.MuiButtonBase-root.MuiIconButton-root {
    /* top: calc(50% - 20px) !important; */
    color: #54a3a1;
    /* cursor: pointer; */
    /* margin: 0 10px; */
    /* position: relative; */
    /* font-size: 30px; */
    /* transition: 200ms; */
    background-color: #4e979729;
}
.MuiButtonBase-root.MuiIconButton-root.Carousel-button-20 {
    color: #0d4e8f !important;
}

.closeIcon .MuiSvgIcon-colorAction {
    color: rgb(13 78 143);
}
.carousalActiveBtn
{
    color:#0d4e8f !important
}
