.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
    padding: 10px 0px 10px 10px !important;
    border: none !important;
}
.MuiChip-root {
    color: white !important;
    background-color: #007bff !important;
}
.MuiChip-deleteIcon {
    color: white !important;
}
.boldText {
    font-weight: bold !important;
}
.italicsText {
    font-style: italic !important;
}
.underLineText {
    text-decoration: underline;
}
.justifyText {
    text-align: justify !important;
}
.alignCenterText {
    text-align: center !important;
}
.textEditPanel {
    margin-left: 2px !important;
}
.textEditPanel .MuiIconButton-root {
    padding: 0px;
    margin-left: 8px;
}
.textEditPanel .MuiSvgIcon-fontSizeSmall {
    font-size: 15px;
    color: #3f51b5;
}
#broadCastMessageModal .modal-header {
    background-color: #3f51b5;
    color: white;
}
.subjectText {
    width: 100%;
    margin-left: 1px !important;
}