.dataview-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4em;
    margin: 10px;
}

.grid-flex {
    display: flex !important;
}
.toolTip{
    background-color: white;
    color: black;
    box-shadow: 1% ;
    font-size: 11px;
   
   padding: 10px
}
.toolTipWrapper{
    max-width: 220px;
    width: 100px;
    border: '1px solid #dadde9';
}
.smBtnDisabled{
    opacity: 0.75;
    background: linear-gradient(168deg,#1a87d3 -10%,rgba(135,55,124,0.25) 10%,rgba(229,195,222,0.15) 40%,rgba(229,195,222,0.1) 53%),linear-gradient(112deg,#1f7e36,#266765 32.1%,#18ff34 90%)
}

.green {
    color: green;
}

.red {
    color: #dc3545;
}