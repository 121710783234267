
.contact-view-page {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin: 0 1rem 0 0.5rem;
}

#contact-table-area{
    padding-bottom: 1.5rem;
}

#contact-details-table-body tr:first-child:hover{
    background-color: #8fd19e24;
    color: black;
}

#contact-detail-row:hover{
    background-color: #8fd19e24;
    color: black;
}
.customerMailModal{
    width: 350px !important;
    min-Width: 425px!important;
    max-Width: 440px!important;
    position: absolute!important;
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%) !important;    
}

#no-contacts {
    text-align: center;
    color: darkgray;
}

.center-of-screen {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

/* Create Customer Modal */

#create-customer-modal {
    width: 50%;
    min-width: 480px;
}

.createCustomerForm {
    margin: 00.5rem;
    overflow: scroll;
    overflow-x: hidden;
    max-height: 55vh;
}

.createCustomerSubForm {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
}

.createCustomerSubFormHeader {
    background: #e9ecef;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.field-and-label {
    display: flex;
    width: 85%;
    flex-direction: row !important;
}

.field-and-label > label {
    flex: 1;
    margin: auto 0;
}

.form-modal-header {
    margin: 0.5rem !important;
}