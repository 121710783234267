#ranges{

}
#ranges-row{
    display: flex;
}
#range-col{
margin: 1rem ;
}
#range-col input{
    border-radius: 0.2rem;
    height: 2rem;
}

.unms-button{
    width: 8rem;
}
#unms-range{
    padding: 0 1.5rem;
}
#unms-range-header{
    height: 3rem;
    font-size: 1.5rem;
    background-color: hsla(0,0%,82.7%,.18);
    margin-bottom: 1rem;
    padding-top: 5px;
}

.unms-button-area{
    display: inline-block;
}
#unms-button-add{
    margin-left: 1rem;
}
.my-m{
    margin: 0.6rem 0;
}
.my-s{
    margin: 0.5rem 0;
}

.my-xl{
    margin: 1.5rem 0;
}

.checkout-key{
    font-weight: bold;
}
.checkout-key + span{
    color: green;
    font-weight: bold;
}
.py-m{
    padding: 4px 0;
}

#empty-content{
    font-size: x-large;
    color: #dc3545;
    padding: 0 1.5rem;
    margin: 1.5rem 0;
    vertical-align: middle;
}
#empty-content i{
    margin-right: 10px;
    vertical-align: middle;
}

.failed-area{
    font-size: x-large;
    padding: 0 1.5rem;
    margin: 1.5rem 0;
    vertical-align: middle;
}
.failed-area span{
    margin-right: 10px;
    vertical-align: middle;
    color: #e83319;
}

#resource-table{
    margin: 0.4rem 1rem;
}

#resource-table tbody tr:hover{
    /* background-color: #8fd19e; */
    background-color: #8fd19e24;
    color: black;
}
#resource-table thead{
    /* background-color: #8fd19e5e; */
    background-color: hsla(0,0%,82.7%,.18);
}



#unms-success>p{
    padding: 10px;
    }
    
    #unms-success>p>strong{
        font-weight: 800;
        }
    
    #unms-success-content{
        padding: 10px;
        border-radius: 5px;
        background-color: #def3debd;
        margin: 5px;
    }
    #unms-success-content>p{
        padding: 5px;
        margin: 0;
    }
    
    #unms-success-content>p>strong{
        font-weight: 800;
    }