.showSalesForceLabel {
    background-color: transparent !important;
    border-radius: 3px;
    font-weight: 500;
}
.showSalesForceLabel .MuiPaper-elevation1 
{
    background-color: transparent !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 0px;
    color: #1d1d1d;
    font-weight: 700;
    text-align: left;
}
.repaymentDataGrid .MuiPaper-elevation1
{
    background-color: transparent !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 0px;
    color: #1d1d1d;
}
.repaymentPaper {
    margin-left: 10px;
    background-color: #f2f5f9;
}
.showSalesForceLabel .MuiGrid-spacing-xs-3
{
    margin-bottom: 0px !important;
}
.showSalesForceLabel .MuiGrid-spacing-xs-3 > .MuiGrid-item
{
    padding-left: 0px;
}
.marginRight_Negative10
{
    margin-right :-10px !important;
}
.marginLeft_Negative15
{
    margin-left :-15px !important;
}
.marginLeft_sixty
{
    margin-left:60px !important;
}
.marginLeft_sixtyFive
{
    margin-left:65px !important;
}
.showSalesForceLabel .MuiGrid-spacing-xs-3 , .repaymentDataGrid .MuiGrid-spacing-xs-3
{
    margin: -8px;
}
.mobileDataRowGrid{
    margin-top: 16px !important;
}

repaymentDataGrid .MuiPaper-elevation1 
{
    background-color:transparent !important;
}
.repaymentDataRowGrid{
    margin-top: -10px !important;
    margin-left: -8px !important;
}
.repaymentDataRowGrid .mobile-dorpdown
{
    margin-top:22px !important;
}
.addRepaymentGrid {
    margin-left: 50px;
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.msisdnLabel{
    margin-right: 90px !important;
}
.dlcLabel {
    margin-left: 81px !important;;
}
.salesforceStatus{
    margin-left: -50px !important
}
.salesforceStatus .MuiPaper-elevation1{
    width: 100px !important;
}
.contractLabel
{
    margin-left:-50px !important
}

.imeiLabel
{
    margin-left:-40px !important
}
.finalAmntLabel {
    margin-left: -35px !important;
}
.finalAmntLabel input {
    border-radius: 3px !important;
    margin-top: -10px !important;
    width: 80px !important;
}
.finalTermLabel { margin-left: -50px !important}
.finalTermLabel input { 
    border-radius: 3px !important;
    margin-top: -10px !important;
    width: 80px !important;}
.salesforceStatus select.form-control {
        margin-top: -9px !important;
        width: 115px;
    }
.monthlyAmnt{ margin-left: -50px !important}
.salesForceUpdateButton {
    margin-left: 40px !important;
    margin-top: -10px !important;
}
.noRecords
{
    font-weight: 700;
    font-size: 25px;
    margin-left: 430px !important;
}