.td-sm-icon {
    font-size: 2rem !important;
    color: #007bff !important;
    font-family: td-icons;
    font-style: normal;
    font-weight: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    font-variant: normal;
}

h5 {
    margin: 0 !important;
}

.env-modal-title {
    font-size: 1.17em !important;
    font-weight: 600;
}

select {
    -webkit-appearance: menulist !important;
}