.testimonialsCarousal
{
    background-color: whitesmoke;
    width: 100% !important;
    border-radius: 5px;
    box-shadow: 1px 1px 10px #ced6ea;
    min-height: 500px;
}
.carousalPanel .CarouselItem {
    /* background-color: lavender; */
    margin: 0px 60px 0px 60px;
    height: auto !important;
    overflow-y: auto !important;
    box-shadow: 1px 1px 1px 1px #97bdee1f;
}
.testimonyCard {
    box-shadow: 1px 1px 1px 1px #97bdee1f;
}
.testimonialHeader {
    font-size: 30px !important;
    font-weight: 800;
    font-family: monospace;
}
.testimonyHead
{
    content: "";
    min-height: 100%;
    background-repeat:repeat-y;
    height: 35px; /* As required */
    display:inline-block;
}
.testimonyCard {
    max-width: 300px !important;
}

.quote-icon
{
}
.feedbackPanel
{
    border-bottom: 1px solid #eaeef4;
    width: 115%;
    margin-left: -16px;
}
.feedbackUserDataPanel
{
    width: 115%;
    margin-left: -16px;
    background-color: #97bdee0a;
    height: 63px;
    margin-bottom: -25px;
    padding-top: 10px;
}
.pageHeader {
    color: #737272;
    padding-top: 20px;
    padding-left: 5px;
    font-size: 22px;
}

/* .testimonialCarousal .slider-single-content {
    max-width: 330px !important;
    border: 1px solid grey;
    border-radius: 17px;
    box-shadow: 5px 0 10px #ffffffd6 !important;
    opacity: 1 !important;
}
.feedbackContainer {
    background-color: white !important;
    border-radius: 13px !important;
} */
.testimonialCarousal .react-3d-carousel {
    margin-top: 0px !important; 
    box-shadow: none !important;
    border-radius: 20px;
    height: 300px !important;
}
.container.feedbackContainer{
    max-width: 360px !important;
    min-height: 245px !important;
    background-color: white !important;
    border: 2px solid #dedbdb;
    border-radius: 15px;
}
.feedbackQuote
{
    color:#250f98 !important
}
.commentText
{
    color:#757479 !important;
    min-height: 100px !important;
}
.userText
{
    color:#757479 !important;
}

.testimonialCarousal .slider-single.proactive {
    margin-left: 182px !important;
}
.testimonialCarousal .slider-single.preactive {
    margin-left: -182px !important;
}
.testimonialCarousal .slider-right {
    margin-left: 80px !important;
}
.testimonialCarousal .slider-left {
    margin-right: 90px !important;
}
.customerReviewText {
    font-size: 30px !important;
    color: #6d6a6a;
}

