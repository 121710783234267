.cog-icon {
    color:#1ba1e2;
}

.tableField {
    height: 36px !important;

}

.checkbox {
    align-items: unset !important;
    margin-right: 100% !important;
}

.checkbox > .form-control-label {
    margin-right: 10px;
}

.css-16yifmq {
    height: 37px !important;
}

.s2p-form-btn {
    margin: 0 42%;
}
.s2p-checkbox input[type="checkbox"] {
    left: 99% !important;
}

.fileUploadDropZone {
    height: 200px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    cursor: pointer;
    color: #969292;
    outline: 2px dashed #7b89c1;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
}

.fileUploadDropZone:hover strong {
    color: #5fbcea;
}

.fileUploadDropZone--over {
    outline: 2px dashed #7b89c1;
    outline-offset: -20px;
    background-color: #f0eeee42;
}

.fileUploadDropZoneIcon {
    width: 100%;
    color: #7b89c1 !important;
    display: block;
    margin-bottom: 20px;
    font-size:4.2rem !important;
}

.s2pArya-download-section {
    padding: 20px 30px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.s2pArya-download-section p + p {
    margin-top: 0px !important;
}