#productEditButton {
    width: calc(100% - 12px);
    padding-top: 4px;
    padding-bottom: 2px;
}

.productSelected {
    font-size: large;
    margin-left: 50px;
}

.s2pProductRow {
    display: flex;
    justify-content: space-between;
    text-align: justify;
}

.producttable #product-modal {
    max-width: "50vw";
}

.product-modal2 {
    max-width: "50vw";
}

#cidn-input input[readonly] {
    background-color: lightgray;
}

#adbor-fnn-table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    background-color: rgba(211, 211, 211, 0.18);
    border-radius: 10px;
}

#adbor-fnn-table tr:hover {
    cursor: pointer;
}

#product-table-header {
    padding-bottom: 15px
}

#product-input-header {
    padding-bottom: 13px
}

#adbor-fnn-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
}

#adbor-fnn-table td {
    white-space: normal;
    word-wrap: break-word;
    text-align: left;
    vertical-align: auto;
}

#adbor-fnn-table thead {
    border-bottom: 0px;
}

#product-table-col {
    padding-left: 5px;
    padding-right: 5px;
}

#product-input-table-col {
    padding-left: 5px;
}

#adbor Input {
    width: 165px;
    height: 34px;
}

#fnn Input {
    width: 165px;
    height: 34px;
}

.product-header {
    font-weight: bold;
    padding-right: 5px;
}
.group-header {
    font-weight: bold;
    padding-left:20px;
    
}
#select-quantity {
    height: 23px;
    padding: 0px 8px;
    width: 60px;
}

#qty-dropdown {
    position: absolute;
}

#header-mobile {
    display: flex;
    justify-content: space-between;
    padding-bottom: 13px;
  }
  
.mobile-dorpdown {
    position: relative !important;
    display: inline-block;
}
  
.product-family {
    height: 23px !important;
    padding: 0px 8px !important;
    width: 152px !important;
    font-size: 14px;
}
.cosmos-product-family {
    height: 38px !important;
    padding: 0px 8px !important;
    width: 152px !important;
    font-size: 14px;
    border-radius: .25rem;
}

@media only screen and (max-width: 1300px) {
    #mobile-item > span {
      display: block;
      padding-bottom: 10px;
    }
}

.adbor-fnn-table input[type="textWithCustomStyles"]{
    padding: 0 5px !important
}

.box-toggler {
    --primary-color: #13a129;
    --light-color: #f3f3f3;
    --dark-color: #333;
    padding: 3px 0;
}
 
  /* box toggle checkbox in s2p modal */
.box-toggler input[type="checkbox"] {
    position: absolute;
    width: 35px;
    height: 35px;
    z-index: 999;
    opacity: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.box-toggler input[type="checkbox"] + label {
  display: block;
  position: relative;
  margin-bottom: 20px;
  font: 14px/20px "Open Sans", Arial, sans-serif;
  color: #ddd;
}

/* box */
.box-toggler input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  border: 2px solid var(--dark-color);
  position: absolute;
  top: 0;
  opacity: 0.6;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transition: all 0.12s, border-color 0.08s;
  transition: all 0.12s, border-color 0.08s;
}

/* checked sign */
.box-toggler input[type="checkbox"]:checked + label:before {
  width: 10px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-color: var(--primary-color);
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.box-toggler input[type="checkbox"]:hover {
  cursor: pointer;
}

.box-toggler input[type="checkbox"] + label:last-child {
  margin-bottom: 0;
}

.verticle-middle {
    vertical-align: middle !important;
}
.repaymentsPanel
{
    display: flex;
}
.addRepayments {
    color: green !important;
    border: 12px solid white !important;
    cursor: pointer !important;
}

.removeRepayments {
    color: red !important;
    border: 0px !important;
    cursor: pointer !important;
    background-color: transparent !important;
    font-size: 25px;
}

.mobileDataGrid {
    background-color: #e4e4e494;
    border-radius: 3px;
    font-weight: 500;
}

.mobileDataGrid .MuiPaper-elevation1 , .repaymentDataGrid .MuiPaper-elevation1
{
    background-color:transparent !important;
    box-shadow: none;
    margin-top: 0px;
}
.repaymentPaper {
    margin-left: 10px;
    background-color: #f2f5f9;
    margin-bottom: 20px;
}

.mobileDataGrid .MuiGrid-spacing-xs-3
{
    margin-bottom: 0px !important;
}
.mobileDataGrid .MuiGrid-spacing-xs-3 > .MuiGrid-item
{
    padding-left: 0px;
}
.marginRight_Negative10
{
    margin-right :-10px !important;
}

.marginLeft_Negative15
{
    margin-left :-15px !important;
}

.mobileDataGrid .MuiGrid-spacing-xs-3 , .repaymentDataGrid .MuiGrid-spacing-xs-3
{
    margin: -8px;
}
.mobileDataRowGrid{
    margin-top: 16px !important;
}

.repaymentDataGrid
{
    margin-top: 1px !important;
    background-color: #f3f1f194;
    border-radius: 3px;
    font-weight: 400;
    height: 46px;
    margin-left: -1px !important;
    width: 1078px !important;
}
repaymentDataGrid .MuiPaper-elevation1
{
    background-color:transparent !important;
}
.repaymentDataRowGrid{
    margin-top: -10px !important;
    margin-left: -8px !important;
}
.repaymentDataRowGrid .mobile-dorpdown
{
    margin-top:22px !important;
}
.addRepaymentGrid {
    margin-left: 50px;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.dlcLabel {
    margin-left: 81px !important;;
}
.telstraNumberLabel{
    margin-left: -25px !important
}
.telstraNumberLabel .MuiPaper-elevation1{
    width: 100px !important;
}
.enterpriseHeader {
    background-color: #fff3e8 !important;
    border-radius: 3px !important;
    width: 100% !important;
    line-height: 40px;
}
.enterpriseHeader.row , .groupHeader.row ,.serviceHeader.row ,.extHeader.row{
    color: #4e4b4b !important;
    font-weight: 300 !important;
    font-family: 'TelstraAkkuratWeb-Regular' !important;
    border: 1px solid #ececec !important;
}
.groupHeader {
    background-color: rgb(233 242 255) !important;
    border-radius: 3px !important;
    margin-left: -21px !important;
    min-width: 100% !important;
    line-height: 35px;
}
.serviceHeader {
    background-color: rgb(224 247 229) !important;
    border-radius: 3px !important;
    margin-left: -21px !important;
    min-width: 100% !important;
    line-height: 40px;
}
.extHeader {
    background-color: rgb(236 232 241) !important;
    border-radius: 3px !important;
    margin-left: -21px !important;
    min-width: 100% !important;
    line-height: 40px;
}
.enterpriseData{
    width: 100% !important;
}
.groupData {
    margin-left : -25px !important
}